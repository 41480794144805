import AsyncCache from "./AsyncCache";
import { absURL, absURLStr, checkAndParse } from "./Riphub";
import { CurationDataType } from "./Germline";
import { SmartCategories } from "./Mastermind";

const articleQCache = new AsyncCache(16, fetchArticleQueueInner);
const variantQCache = new AsyncCache(16, fetchVariantQueueInner);
const projectVariantCache = new AsyncCache(16, fetchProjectVariantsInner);
const projectArticleCache = new AsyncCache(16, fetchProjectArticlesInner);
export const baseRoutingPath = "projects";
export const baseProjectPath = "projects";

export const fetchArticleQueue = articleQCache.fetch;
export const fetchVariantQueue = variantQCache.fetch;
export const fetchProjectVariants = projectVariantCache.fetch;
export const fetchProjectArticles = projectArticleCache.fetch;

export function clearQueues() {
  articleQCache.clear();
  variantQCache.clear();
}

export function focusPath(
  projectID: string,
  pmid: string,
  variant: string,
  focus: "article" | "variant"
): string {
  return (focus === "article" ? articleFocusPath : variantFocusPath)(
    projectID,
    pmid,
    variant
  );
}

export function focusBasePath(
  projectID: string,
  pmid: string,
  variant: string,
  focus: "article" | "variant"
): string {
  return focus === "article"
    ? articlePath(projectID, pmid)
    : variantPath(projectID, variant);
}

export function saveEvidencePath(projectID: string): string {
  return `/projects/${projectID}/save_evidence`;
}

export const fetchFunctionalCriteriaPath = (symbol: string): string =>
  `/genes/${symbol.toUpperCase()}/functional_criteria`;

export const saveVariantDescriptionsPath = (
  projectID: string,
  variantID: string,
  pmid: string
): string => {
  return `/projects/${projectID}/variants/${variantID}/pmids/${pmid}/save_variant_descriptions`;
};

export function articlePath(projectID: string, pmid: string): string {
  return `/${baseRoutingPath}/${projectID}/pmids/${pmid}`;
}

export function articleFocusPath(
  projectID: string,
  pmid: string,
  variant: string
): string {
  return `/${baseRoutingPath}/${projectID}/pmids/${pmid}/variants/${variant}`;
}

export function variantPath(projectID: string, variant: string): string {
  return `/${baseRoutingPath}/${projectID}/variants/${variant}`;
}

export function variantFocusPath(
  projectID: string,
  pmid: string,
  variant: string
): string {
  return `/${baseRoutingPath}/${projectID}/variants/${variant}/pmids/${pmid}`;
}

export function reopenPath(
  projectID: string,
  pmid: string,
  variant: string
): string {
  return `/${baseRoutingPath}/${projectID}/pmids/${pmid}/variants/${variant}/reopen`;
}

async function fetchArticleQueueInner(
  projectID: string,
  variantID: string,
  pmid?: string,
  suppOnly: boolean = false
): Promise<ArticleQueueResponse> {
  let path = `/projects/${projectID}/variants/${variantID}/article_queue`;
  if (pmid !== undefined) {
    path += `/${pmid}`;
  }
  const url = absURL(path);
  url.searchParams.set("supplemental_only", suppOnly ? "true" : "false");
  let json = await checkAndParse<ArticleQueueResponse>(fetch(url.toString()));
  return json;
}

async function fetchVariantQueueInner(
  projectID: string,
  pmid: string,
  variantID?: string,
  suppOnly: boolean = false,
  showBelowMatchCountThreshold: boolean = false
): Promise<VariantQueueResponse> {
  let path = `/projects/${projectID}/pmids/${pmid}/variant_queue`;
  if (variantID !== undefined) {
    path += `/${variantID}`;
  }
  const url = absURL(path);
  url.searchParams.set("supplemental_only", suppOnly ? "true" : "false");
  url.searchParams.set(
    "show_below_match_count_threshold",
    showBelowMatchCountThreshold ? "true" : "false"
  );

  let json = await checkAndParse<VariantQueueResponse>(fetch(url.toString()));
  return json;
}

async function fetchProjectVariantsInner(
  projectID: string
): Promise<ProjectVariants> {
  let rec = await checkAndParse<ProjectVariants>(
    fetch(absURLStr(`/projects/${projectID}/show_variants`))
  );
  // sort descending by articles and then by variant ID
  rec.variants.sort((a, b) => {
    const countD = b.articles - a.articles;
    if (countD !== 0) {
      return countD;
    }
    // break ties by article ID
    return a.id.localeCompare(b.id);
  });
  return rec;
}

async function fetchProjectArticlesInner(
  projectID: string
): Promise<ProjectArticles> {
  let rec = await checkAndParse<ProjectArticles>(
    fetch(absURLStr(`/project/${projectID}/show_articles`))
  );
  // sort descending by score and then by PMID
  rec.articles.sort((a, b) => {
    const scoreD = b.score - a.score;
    if (scoreD !== 0) {
      return scoreD;
    }
    return parseInt(a.pmid) - parseInt(b.pmid);
  });
  return rec;
}

export function findArticleEntry(
  queue: ArticleQueueRec,
  pmid: string
): ArticleQueueEntry | undefined {
  return queue.find((e) => e.pmid === pmid);
}

export function findVariantEntry(
  queue: VariantQueueRec,
  variantId: string
): VariantQueueEntry | undefined {
  return queue.find((e) => e.variant_id === variantId);
}

export interface ArticleQueueResponse {
  queue: ArticleQueueRec;
  canonical: boolean;
}

export interface VariantQueueResponse {
  queue: VariantQueueRec;
}

export type ArticleQueueRec = ArticleQueueEntry[];
export type VariantQueueRec = VariantQueueEntry[];

export type Status = "open" | "closed";

export interface QueueEntry {
  status: Status;
  evidence_items?: Evidence[];
  manual_addition: boolean | null;
}

export interface ArticleQueueEntry extends QueueEntry {
  gene_symbol: string;
  pmid: string;
  score: string;
  smart_categories: SmartCategories;
  match_texts?: string[];
}

export interface VariantQueueEntry extends QueueEntry {
  variant_id: string;
  gene_symbol: string;
  ontology_relations: any[];
  canonical?: boolean;
  variant_group?: string;
  excluded_parent?: string;
}

export interface Audit {
  username: string;
  created_at: string;
}

export interface Evidence {
  id: string;
  variant_mention_id: string;
  evidence: string;
  context: string;
  category: string;
  evidence_tag_applications: EvidenceTagApplication[];
  alias_variant_id: string | null;
  cases_count?: number | null;
  data?: CurationDataType;
  evidence_text: string;
  evidence_type: string;
  last_audit?: Audit;
}

export interface EvidenceTagApplication {
  evidence_tag: EvidenceTag;
}

export interface EvidenceTag {
  id: number;
  name: string;
  description: string;
}

export interface ProjectVariants {
  variants: ProjectVariant[];
  genes: ProjectGene[];
  total_tasks: number;
  total_variants: number;
}

export interface ProjectVariant {
  id: string;
  gene_id: string;
  ref_type: string;
  articles: number;
  gene_syn_count: number;
  top_ten: string[];
  match_texts: string[];
  gene_match_texts: string[];
  match_symbol: boolean;
}

export interface ProjectArticles {
  articles: ProjectArticle[];
  by_disease: Counted[];
  by_journal: Counted[];
  by_pub_type: Counted[];
  closed_count: number;
  closed_by_variant: { [key: string]: number };
}

export interface ProjectArticle {
  pmid: string;
  title: string;
  journal_iso: string;
  journal_title: string;
  pub_date: string;
  score: number;
  genes: ArticleGeneEntry[];
  variants: number;
  closed_variants: number;
  is_reviewed: boolean;
  disease_cat: string;
  na_cat: string;
}

export interface ArticleGeneEntry {
  id: string;
  matched_symbol: string;
  matched_text: string[];
}

export interface ProjectGene {
  id: string;
  count: number;
  match_texts: GeneMatch[];
}

export interface GeneMatch {
  match: string;
  count: number;
}

export interface Counted {
  id: string;
  count: number;
}

export interface VariantEntry {
  pmid: string;
  gene: string;
  variant: string;
  manual_addition: boolean | null;
}

export function makeEntry(
  pmid: string,
  gene: string,
  variant: string,
  manual_addition: boolean | null
): VariantEntry {
  return { pmid, gene, variant, manual_addition };
}

export interface EvidenceData {
  evidence_category: string;
  evidence_text: string;
  evidence_tag_ids: number[];
  evidence_context: string;
  alias_variant_id: string | null;
  cases_count?: number | null;
}

export type EditRequest = EvidenceData & {
  gene_symbol: string;
};

export type SaveRequest = EvidenceData & {
  entries: VariantEntry[];
  close_task: boolean;
};

export function filterVariantQueue(
  queue: VariantQueueEntry[],
  projV: ProjectVariants
): VariantQueueEntry[] {
  const shown = new Set<string>(projV.variants.map((pv) => pv.id));
  return queue.filter((entry) => shown.has(entry.variant_id));
}

export function nextVariantAfter(
  pvs: ProjectVariants,
  current: string
): string | undefined {
  const i = pvs.variants.findIndex((pv) => pv.id === current);
  if (i == -1 || i == pvs.variants.length - 1) {
    return;
  }
  return pvs.variants[i + 1].id;
}

export type TagOption = {
  name: string;
  id: number;
};

export const ALIAS_CAT = "alias";
export const FP2_CAT = "fp2";
export const TEMP_FP2_CAT = "temp_fp2";
export const TEMP_ALIAS_CAT = "temp_alias";
export const DUAL_CATS = [
  "ps3_os2",
  "bs3_sbs2",
  "pm4_om2",
  "ps1_os1",
  "pvs1_ovs1",
];
export const MULTI_CASE_CATS = ["sm", "um", "gm", "os3", "om3", "op2", "op3"];

export const TAG_REQUIRED_CATS = [
  "pp1",
  "pp1m",
  "ps4m",
  "ppc",
  "ppc_homo",
  "ppc_het",
  "ppc_compound",
  "op2",
  "sc",
  "sm",
  "uc",
  "um",
  "gc",
  "gm",
];

export const AUTO_CATS = [
  "pvs1",
  "pm1",
  "pm2",
  "pm4",
  "pm5",
  "pp2",
  "pp3",
  "bp3",
  "ba1",
  "bp1",
  "bp7",
  "bs1",
  "bp4",
  "ovs1",
  "os3",
  "om1",
  "om2",
  "om3",
  "om4",
  "op1",
  "op3",
  "op4",
  "sbvs1",
  "sbs1",
  "sbp1",
  "sbp2",
];

export const CONTEXT_TEMPLATES: {
  id: string;
  label: string;
  disabled?: boolean;
}[] = [
  { id: "select", label: "Select a template" },
  {
    id: "hgvs-translation",
    label:
      'Author refers to this variant as "c.___" and "p.___"; the HGVS translation for this variant is "c.___" and "p.___" based on NM_______.',
  },
  {
    id: "digenic-variant",
    label:
      "Digenic variant. Patient with another variant described as causative in ___ gene.",
  },
  {
    id: "dual-diagnosis",
    label: "Dual diagnosis.",
  },
  {
    id: "cohort-registry",
    label: 'Patient found in "_" cohort/registry.',
  },
  {
    id: "previously-reported",
    label:
      "Patient(s) previously reported in PMID_____ without a molecular cause described.",
  },
  {
    id: "reference-unfound",
    label:
      "Reference ____ is unfound and therefore the proband is curated in this PMID.",
  },
  {
    id: "unspecified-patients",
    label:
      "Number of patients with this mutation is not specified; it can be assumed that there is at least one.",
  },
  {
    id: "conference-abstract",
    label:
      "The source of this curation evidence is from a conference abstract.",
  },
  {
    id: "uncorrected-proof",
    label: "The source of this curation evidence is from an uncorrected proof.",
  },
  {
    id: "pmid-correction",
    label: "Correction described in [PMID].",
  },
];

export const NON_ENGLISH_CONTEXT_TEMPLATES: {
  id: string;
  label: string;
  disabled?: boolean;
}[] = [
  {
    id: "non-english",
    label:
      "Variant curated from English information provided in the abstract. A full review of the article wasn’t completed.",
  },
];

export const SEGREGATION_CONTEXT_TEMPLATES: {
  id: string;
  label: string;
  disabled?: boolean;
}[] = [
  {
    id: "meioses-threshold",
    label:
      "The number of meioses shown reaches the PP1 moderate threshold for this gene. Additional meioses in this pedigree may be present.",
  },
  {
    id: "limited-segregation",
    label:
      "Limited segregation information available. The number of meioses were inferred based on the number of affected relatives.",
  },
];

export const UNRELATED_PROBAND_CONTEXT_TEMPLATES: {
  id: string;
  label: string;
  disabled?: boolean;
}[] = [
  {
    id: "assumed-in-trans",
    label: "Variant is assumed in trans with _.",
  },
];

export const UNRELATED_PROBAND_UNWEIGHTED_CONTEXT_TEMPLATES: {
  id: string;
  label: string;
  disabled?: boolean;
}[] = [
  {
    id: "unweighted-context-header",
    label:
      "If patient is curated as unweighted case but found to have de novo/segregation evidence:",
    disabled: true,
  },
  {
    id: "unweighted-de-novo",
    label: "Variant was found to occur de novo in this patient.",
  },
  {
    id: "unweighted-segregation",
    label: "Variant was found to segregate with disease in this family.",
  },
];
