import React from "react";
import { DescriptionData } from "./Common";
import { EvidenceTag, TagOption } from "./Curation";
import { Gene, GermlineDefaults } from "./Gene";
import { Tag } from "./Project";

export const isCriteriaApplied = (
  criteria: string,
  gene: Gene,
  defaults: GermlineDefaults
) => {
  const field = `apply_${criteria}`;

  if (field in gene) {
    let applied = gene[field];
    if (applied !== null) return applied;
    if (!(field in defaults) || defaults[field] === null) return false;
    return defaults[field];
  } else {
    // Field does not have an associated apply
    // field and is considered always applied.
    return true;
  }
};

export function enumOptions(
  enumItem: any,
  disabled: boolean = false
): JSX.Element {
  return (
    <option
      aria-label={enumItem[0]}
      value={enumItem[1]}
      key={`key-${enumItem[0]}`}
      disabled={disabled}>
      {enumItem[1]}
    </option>
  );
}

export function activeEnumOptions(
  data: Record<string, DescriptionData>,
  currentValue?: string
): JSX.Element[] {
  return Object.entries(data)
    .filter(
      ([key, value]) =>
        !value.inactive || (currentValue && currentValue === key)
    )
    .map(([key, value]) => enumOptions([key, value.description]));
}

export function arrayOption(item: string): JSX.Element {
  return (
    <option aria-label={item} value={item} key={`key-${item}`}>
      {item}
    </option>
  );
}

export const formatDiseaseTagOption = (tag: Tag | EvidenceTag): TagOption => ({
  id: tag.id,
  name: `${tag.name} - ${tag.description}`,
});

export interface UnrelatedProbandEntry {
  zygosity: string;
  probands?: number;
  alleles?: number;
  unweighted: boolean;
  unweighted_reason?: string;
  other_unweighted_reason?: string;
  in_trans?: string[];
  prev_pmid?: string[];
}

export interface HealthyMutantEntry {
  carrier_age?: number;
  unaffected: boolean;
}

export interface PopulationEvidenceEntry {
  subpopulation: string;
  allele_count?: number;
  total_alleles?: number;
}

export interface ComputationalEntry {
  prediction_model: string;
  score?: number;
}

export interface FunctionalEntry {
  functional_type: string;
  value?: number;
  unit_test?: string;
  exclude: boolean;
  functional_impact?: string;
  functional_criteria?: string;
  model?: string;
  cell_type?: string;
  translated_category?: string;
}

export const initialFunctionalEntry: FunctionalEntry = {
  functional_type: "",
  exclude: false,
};

export interface FpNaAliasEntry {
  fp_na_alias_type: string;
  na_type?: string;
  fp_type?: string;
  alias_variant_id?: string;
  alias_type?: string;
  note?: string;
  flag_issue?: string;
  review?: string;
}

export const initialFpNaAliasEntry: FpNaAliasEntry = {
  fp_na_alias_type: "",
};

export interface DiseaseAndEvidenceEntry {
  evidence: string;
  context: string;
  diseaseTags: TagOption[];
}

export interface SegregationEntry {
  segregation_type: string;
  meioses?: number;
  affected?: number;
}

export interface DeNovoEntry {
  de_novo_type: string;
  probands?: number;
}

export interface SplicingEntry {
  splicing_impact: string;
  impact_degree?: string;
  impact_type?: string;
  remove_pvs1?: boolean;
  other_impact?: string;
}

export interface DirectApplicationEntry {
  category: string;
  strength: string;
}

export type CurationDataType =
  | UnrelatedProbandEntry
  | HealthyMutantEntry
  | PopulationEvidenceEntry
  | ComputationalEntry
  | FunctionalEntry
  | FpNaAliasEntry
  | SegregationEntry
  | DeNovoEntry
  | SplicingEntry
  | DirectApplicationEntry
  | undefined;

export interface GermlineEvidenceData {
  evidence_type: string;
  evidence_text: string;
  evidence_tag_ids: TagOption[];
  evidence_context: string;
}

export type GermlineSaveRequest = GermlineEvidenceData & {
  entries: VariantEntry[];
  close_task: boolean;
  data?: CurationDataType;
};

export interface VariantEntry {
  pmid: string;
  gene: string;
  variant: string;
  manual_addition: boolean | null;
}

export type GermlineEditRequest = GermlineEvidenceData & {
  data?: CurationDataType;
  gene_symbol: string;
};

export const GERMLINE_DIS_TAG_REQUIRED_CATS = ["ps4"];
export const GERMLINE_FX_TAG_REQUIRED_CATS = ["ps3"];
export const GERMLINE_UNWEIGHTED_TAG_NOT_REQUIRED = [
  "insufficient",
  "alternate_cause_diff",
  "add_report",
  "phenotypic_information_not_provided",
  "somatic",
  "potential_somatic",
  "fetus_nondiagnostic",
  "screening_nondiagnostic",
];
